import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout/layout";

export default function privacy() {
  return (
    <Layout>
      <Helmet>
        <title>Chopstick Express | Terms of Service</title>
        <meta charset="utf-8" />
        <meta
          name="description"
          content="Bored? Read the Terms of Service of Chopstick Express after you enjoy the best Chinese food in St. Petersburg, FL."
        />
        <link rel="canonical" href="https://www.chopstickfl.com/privacy" />
      </Helmet>
      <div
        style={{
          backgroundColor: "rgb(252,252,252)",
          textAlign: "left",
          marginBottom: -8
        }}
      >
        <br />
        <div style={{ width: "84%", margin: "auto" }}>
          <h1 style={{ textAlign: "center" }}>Terms of Service</h1>
          <br />
          <p>
            By using FoodWeb LLC. services, you agree to be bound by the
            following terms and conditions.{" "}
          </p>

          <h2>Changes In Terms and Conditions and FoodWeb LLC.</h2>
          <br />
          <p>
            We may modify or terminate our services from time to time, for any
            reason, and without notice, including the right to terminate with or
            without notice, without liability to you, any other user or any
            third party. We reserve the right to modify these Terms of Service
            from time to time without notice. Please review these Terms of
            Service from time to time so that you will be informed of any
            changes.
          </p>
          <br />

          <h2>Disclaimer of Warranties </h2>
          <br />
          <p>
            FoodWeb LLC. disclaims any and all responsibility or liability for
            the accuracy, content, completeness, legality, reliability, or
            operability or availability of information or material displayed in
            the FoodWeb LLC. services. FoodWeb LLC. disclaims any responsibility
            for the deletion, failure to store, misdelivery, or untimely
            delivery of any information or material. FoodWeb LLC. disclaims any
            responsibility for any harm resulting from downloading or accessing
            any information or material on the Internet through the FoodWeb LLC.
            services. The FoodWeb LLC. services, and all materials, information,
            products and services included in FoodWeb LLC. services ARE PROVIDED
            "AS IS," with no warranties whatsoever. FoodWeb LLC. and its
            licensors expressly disclaim to the fullest extent permitted by law
            all express, implied, and statutory warranties, including, without
            limitation, the warranties of merchantability, fitness for a
            particular purpose, and non-infringement of propietary rights.
            FoodWeb LLC. and its licensors disclaim any warranties regarding the
            security, reliability, timeliness, and performance of the FoodWeb
            LLC. services. FastEat Inc and its licensors disclaim, any
            warranties for any information or advice obtained through the
            FoodWeb LLC. services. FoodWeb LLC. and its licensors disclaim any
            warranties for services or goods received through or advertised on
            the FoodWeb LLC. services or through any links provided by the
            FoodWeb LLC. services, as well as for any information or advice
            received through any links provided in the FoodWeb LLC. services.
            you understand and agree that you download or otherwise obtain
            material or data through the use of the FoodWeb LLC. services at
            your own discretion and risk and that you will be solely responsible
            for any damages to your computer system or loss of data that results
            from the download of such material or data.
          </p>
          <br />
          <p>
            Some states or other jurisdictions do not allow the exclusion of
            implied warranties, so the above exclusions may not apply to you.
            You may also have other rights that vary from state to state and
            jurisdiction to jurisdiction.
          </p>
          <br />
          <h2>Limitation of Liability</h2>
          <br />

          <p>
            Under no circumstances shall FoodWeb LLC. or its licensors be liable
            to any user on account of that user's use or misuse of or reliance
            on the FoodWeb LLC. services. Arising from any claim relating to
            this agreement or the subject matter hereof such limitation of
            liability shall apply to prevent recovery of direct, indirect,
            incidental, consequential, special, exemplary, and punitive damages
            whether such claim is based on warranty, contract, tort (including
            negligence), or otherwise, (even if FoodWeb LLC. or its licensors
            have been advised of the possibility of such damages). such
            limitation of liability shall apply whether the damages arise from
            use or misuse of and reliance on the FoodWeb LLC. services, from
            inability to use the FoodWeb LLC. services, or from the
            interruption, suspension, or termination of the FoodWeb LLC.
            services (including such damages incurred by third parties). this
            limitation shall also apply with respect to damages incurred by
            reason of other services or goods received through or advertised on
            the FoodWeb LLC. services or received through any links provided in
            the FoodWeb LLC. services, as well as by reason of any information
            or advice received through or advertised on the FoodWeb LLC.
            services or received through any links provided in the chinese menu
            online services. This limitation shall also apply with respect to
            damages incurred by reason of other services or goods received
            through or advertised on the FoodWeb LLC. services or received
            through any links provided in the chinese menu online services, as
            well as by reason of any information or advice received through or
            advertised on the FoodWeb LLC. services or received through any
            links provided in the FoodWeb LLC. services
            <br />
            Without limiting the foregoing, under no circumstances shall FoodWeb
            LLC. or its licensors be held liable for any delay or failure in
            performance resulting directly or indirectly from acts of nature,
            forces, or causes beyond its reasonable control, including, without
            limitation, Internet failures, computer equipment failures,
            telecommunication equipment failures, other equipment failures,
            electrical power failures, strikes, labor disputes, riots,
            insurrections, civil disturbances, shortages of labor or materials,
            fires, floods, storms, explosions, acts of God, war, governmental
            actions, orders of domestic or foreign courts or tribunals,
            on-performance of third parties, or loss of or fluctuations in heat,
            light, or air conditioning
          </p>
          <br />
          <h2>Miscellaneous Provisions</h2>
          <br />
          <p>
            These terms of service will be governed by and construed in
            accordance with the laws of the state of florida, without giving
            effect to its conflict of laws provisions or your actual state or
            country of residence. If for any reason a court of competent
            jurisdiction finds any provision or portion of the terms of service
            to be unenforceable , the remainder of the terms of service will
            continue in full force and effect.
            <br />
            <br />
            These terms of service constitute the entire agreement between the
            parties with respect to the subject matter here of and supersedes
            and replaces all prior or contemporaneous understandings or
            agreements, written or oral, regarding such subject matter. Any
            waiver of any provision of the terms of service will be effective
            only if in writing and signed by FoodWeb LLC.
          </p>
        </div>
        <br />
      </div>
    </Layout>
  );
}
